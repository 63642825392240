import { IUserLoggedIn } from '../../../models/IUserLoggedIn';
import { IAuthState } from '../../../models/store/IAuthState';
import { defineStore } from "pinia";

export const authStore = defineStore({
  id: 'authStore',
  state: (): IAuthState => ({
    loggedIn: false,
    currentUser: { } as IUserLoggedIn
  }),
  getters: {
    getIsAdmin(state) {
      if (state.currentUser) {
        return state.currentUser?.tipo === 1 ? true : false;
      }
    },
    getPermissionToEdit(state) {
      if (state.currentUser) {
        return state.currentUser?.tipo !== 3 ? true : false;
      }
    },
    getTipoUsuario(state) {
      if (state.currentUser) {
        return state.currentUser.tipo === 1 ? 0 : state.currentUser.tipo
      }
    },
    getContabilistaId(state) {
      if (state.currentUser) {
        return state.currentUser.tipo === 1 ? 0 : state.currentUser.contabilistaId
      }
    }
  },
  actions: {
    LOGIN_SUCCESS(payload: IUserLoggedIn) {
      this.loggedIn = true;
      this.currentUser = payload;
      localStorage.setItem('access_token', this.currentUser.token);
      localStorage.setItem('current_user', JSON.stringify(this.currentUser))
    },
    LOGOUT() {
      this.loggedIn = false;
      this.currentUser = null;
      localStorage.clear();
    },
    GET_USER_LOGGEDIN() {
      if (localStorage.getItem('current_user')) {
        const userLocalStorage: any = localStorage.getItem('current_user');
        this.loggedIn = true;
        this.currentUser = JSON.parse(userLocalStorage);
      }
    }
  }
})

