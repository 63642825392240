import { layoutStore } from '../../../store/modules/layout-store/index';
import { useRouter } from 'vue-router';
import { authStore } from './../../../store/modules/auth/index';
import { computed, defineComponent, reactive } from 'vue';
import { DxToolbar, DxItem } from 'devextreme-vue/ui/toolbar';
import { confirm } from 'devextreme/ui/dialog';
import MediaQ from '../../../helpers/media-query'

export default defineComponent({
  name: 'Header',
  setup() {
    const auth = authStore();
    const layout = layoutStore();
    const router = useRouter();
    const state = reactive({
      logoutButtonOptions: {
        icon: 'mdi mdi-logout mdi-light mdi-18px',
        stylingMode: 'text',
        hint: 'Sair',
        onClick: () => {
          const result = confirm(`
            <span class="flex flex-col items-center">
              <p>Para desconectar do portal clique no botão abaixo</p>
              <i class="mdi mdi-hand-pointing-down" style="font-size: 2rem;"></i>
            </span>`,
            'Deseja Desconectar?');
          result.then(dialogResult => {
            if (dialogResult === true) {
              auth.LOGOUT();
              router.push({ path: '/login' });
            }
          })
        }
      },
      menuButtonOptions: {
        icon: 'mdi mdi-menu mdi-light mdi-18px',        
        stylingMode: 'text',
        hint: 'Menu',
        onClick: () => {
          layout.TOGGLE();
        }
      },
      logo: require('../../../assets/img/logo-white.svg'),
      hiddenUserName: computed(() => MediaQ.lessThanLg())
    })

    return {
      state,
      auth,
    }
  },
  components: {
    DxToolbar,
    DxItem
  }
})