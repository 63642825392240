import { defineStore } from 'pinia';

export const layoutStore = defineStore({
  id: 'layoutStore',
  state: () => ({
    isDrawerOpen: false,
  }),
  actions: {
    TOGGLE(){
      this.isDrawerOpen = !this.isDrawerOpen;
    }
  }
})