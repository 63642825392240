import mitt, { Emitter } from 'mitt'

type Events = {
  popup_toggle: any
  reload_datasource: any
}
const bus: Emitter<Events> = mitt<Events>()

class eventBus {

  usePopup(): any {
    function open(payload = {}) {
      bus.emit('popup_toggle', { status: true, ...payload })
    }

    function close(payload = {}) {
      bus.emit('popup_toggle', { status: false, ...payload })
    }

    function listen(fn: any) {
      bus.on('popup_toggle', fn)
    }

    function off(fn: any) {
      bus.off('popup_toggle', fn)
    }

    return { open, close, listen, off }
  }

  useDataSource(): any {
    function listen(fn: any) {
      bus.on('reload_datasource', fn)
    }

    function reload() {
      bus.emit('reload_datasource')
    }

    function off(fn: any) {
      bus.off('reload_datasource', fn)
    }

    return { listen, reload, off }
  }
}
export default new eventBus();