<template>
  <teleport to="body">
    <DxPopup
      :visible="state.isVisible"
      :title="state.stateRaw.title"
      :width="state.stateRaw.size.width"
      :height="state.stateRaw.size.height"
      :resize-enabled="false"
      :full-screen="state.lessThanLg"
      @hidden="PopupToggle({ status: false })"
      container=".dx-viewport"
    >
      <DxPosition my="center" at="center" of="" />
      <template #content>
        <component
          :is="state.stateRaw.component"
          :propsComponent="state.stateRaw.props"
        ></component>
      </template>
    </DxPopup>
  </teleport>
</template>
<script lang="ts" src="./index.ts"></script>