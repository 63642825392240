<template>
  <DxDrawer
    id="dxDrawer"
    :min-size="state.size"
    :max-size="200"
    :visible="true"
    :close-on-outside-click="true"
    :shading="true"
    :opened-state-mode="state.isModeOpened"
    :animation-duration="250"
    reveal-mode="expand"
    :opened="layout.isDrawerOpen"
    template="list"
  >
    <template #list>
      <NavigationList></NavigationList>
    </template>
    <div id="main">
      <router-view v-slot="{ Component }">
        <transition name="slide" mode="out-in">
          <keep-alive>
            <component :is="Component"></component>
          </keep-alive>
        </transition>
      </router-view>
      <PopupFactory />
    </div>
  </DxDrawer>
</template>

<script src="./index.ts"></script>

<style lang="postcss">
.dx-overlay-content {
  background-color: lightgray;
}
#dxDrawer {
  @apply fixed top-14;
  height: calc(100% - 80px);
}

.dx-drawer .dx-treeview-toggle-item-visibility  {
  @apply text-transparent !important;
}
.dx-drawer-opened .dx-treeview-toggle-item-visibility  {
  @apply text-white !important;
}

#main {
  @apply left-9 p-3  h-full overflow-auto bg-brand-back;
}

.slide-enter,
.slider-leave-to {
  opacity: 1;
}

.slide-move {
  transition: transform 0.5s;
}

.slide-enter-active {
  animation: slide-in 0.5s ease;
}

@keyframes slide-in {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0%);
  }
}
</style>