import { authStore } from './../store/modules/auth/index';
import { tokenExpired } from '../helpers/token-validation'
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Layout from '../layout/index.vue'
import { useToast } from 'vue-toastification';

const toast = useToast();

const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login/index.vue')
  },
  {
    path: '/',
    name: 'Layout',
    component: Layout,
    children: [
      {
        path: 'clientes/:id?',
        name: 'Clientes',
        component: () => import(/* webpackChuckName: "clientes" */ '../views/clientes/index.vue'),
        props: true
      },
      {
        path: 'usuarios',
        name: 'Usuários',
        component: () => import(/* webpackChuckName: "usuarios" */ '../views/usuarios/index.vue'),
        beforeEnter: validateIsAdmin
      },
      {
        path: 'movimentacoes',
        name: 'Movimentações',
        component: () => import(/* webpackChuckName: "movimentacoes" */ '../views/movimentacoes/index.vue')
      },
      {
        path: 'contabilistas',
        name: 'Contabilistas',
        component: () => import(/* webpackChuckName: "contabilistas" */ '../views/contabilistas/index.vue'),
        beforeEnter: validateIsAdmin
      },
      {
        path: 'monofasicos',
        name: 'Consultar Monofásicos',
        component: () => import(/* webpackChuckName: "monofasicos" */ '../views/monofasicos/index.vue')
      },
      {
        path: 'recebimento-xml',
        name: 'Recebimento XML',
        component: () => import(/* webpackChuckName: "recebimento-xml" */ '../views/recebimento-xml/index.vue'),
        beforeEnter: validateIsAdmin
      }
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/clientes',
  }
]

function validateIsAdmin(to: any, from: any, next: any) {
  const store_auth = authStore()
  store_auth.GET_USER_LOGGEDIN();
  
  if (store_auth.getIsAdmin) {
    return next();
  } else {
    toast.error('Você não tem permissão para acessar está página')
    router.back()
  }
}

const router = createRouter({
  history: createWebHistory('/'),
  routes
})

router.beforeEach((to, from, next) => {
  const routesPublic: string[] = ['/login'];
  const routesRequiredAuth = !routesPublic.includes(to.path);
  const isLogged: boolean = tokenExpired();

  if (!isLogged && routesRequiredAuth) {
    localStorage.clear();
    return next({ name: 'Login' });
  }

  next();
})

export default router
