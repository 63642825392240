import { createApp } from 'vue'
import { pinia } from './store'
import App from './App.vue'
import router from './router'
import Toast from "vue-toastification";

import './assets/css/app.css'
import './assets/css/dx.common.css';
import './assets/css/dx.material.esistem-fiscal-compact.css'
import './devextreme-config'
import "vue-toastification/dist/index.css";

createApp(App)
  .use(pinia)
  .use(router)
  .use(Toast)
  .mount('#app')
