import { defineComponent, reactive } from "vue";
import { version } from "../../../../package.json";

export default defineComponent({
  name: 'Footer',
  setup() {
    const state = reactive({
      swhouse: {
        name: "© Copyright 2022 eSistem Sistemas",
        url: "https://autocom-mg.com.br/",
        currentVersion: version
      }
    });

    return {
      state
    };
  },
});