import { layoutStore } from '../../../store/modules/layout-store/index';
import { defineComponent, reactive } from 'vue'
import DxDrawer from 'devextreme-vue/ui/drawer';
import NavigationList from '../navigation-list/index.vue';
import PopupFactory from '../../../components/popup-factory/index.vue';

export default defineComponent({
  name: 'Sidebar',
  setup() {
    const layout = layoutStore();
    const state = reactive({
      size: 34,
      isModeOpened: 'overlap'
    })

    return {
      layout,
      state
    }
  },
  components: {
    DxDrawer,
    NavigationList,
    PopupFactory
  }
})