class MediaQ {
  lessThanLg() {
    const media = window.matchMedia("(max-width: 700px)")
    return media.matches ? true : false
  }

  largeMedia(){
    const media = window.matchMedia("(min-width: 1280px)0")
    return media.matches ? true : false
  }
}
export default new MediaQ()