import { authStore } from '../store/modules/auth';
import { defineComponent, onMounted } from "vue";
import Header from "./components-layout/header/index.vue";
import Footer from "./components-layout/footer/index.vue";
import Sidebar from "./components-layout/sidebar/index.vue";

export default defineComponent({
  components: {
    Header,
    Sidebar,
    Footer
  },
  setup(){
    const store = authStore();

    onMounted(() => {
      store.GET_USER_LOGGEDIN();
    })
  }
});