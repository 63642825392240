import { decode } from 'jsonwebtoken';

export function tokenExpired(): boolean {
  const token = localStorage.getItem('access_token');

  if (token) {
    const { exp } = Object(decode(token)); 
    if (Date.now() >= exp * 1000) {
      //alert('Token Expirado');
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
}