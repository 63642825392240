<template>
  <div id="app-layout">
    <Header></Header>
    <Sidebar></Sidebar>
    <Footer></Footer>
  </div>
</template>
<script src="./index.ts"></script>

<style lang="postcss" scoped>
#app-layout {
  @apply w-full h-full;
}
</style>