import { authStore } from './../../../store/modules/auth/index';
import { layoutStore } from './../../../store/modules/layout-store/index';
import { defineComponent, ref, onMounted, watch } from 'vue';
import { DxTreeView } from 'devextreme-vue/ui/tree-view';
import router from '../../../router';
import mediaQuery from '../../../helpers/media-query';

export default defineComponent({
  name: 'NavigationList',
  components: {
    DxTreeView
  },
  setup() {
    const treeViewRef = ref();
    const store = layoutStore();
    const store_auth = authStore();

    const navigation = [
      { text: 'Clientes', icon: 'mdi mdi-account-group mdi-18px', path: '/clientes' },
      { text: 'Usuários', icon: 'mdi mdi-account-multiple mdi-18px', path: '/usuarios', visible: store_auth.getIsAdmin },
      { text: 'Contabilistas', icon: 'mdi mdi-account-tie mdi-18px', path: '/contabilistas', visible: store_auth.getIsAdmin },
      {
        text: 'Utilitários', icon: 'mdi mdi-apps mdi-18px',
        items: [
          { text: 'Consultar Monofásicos', icon: 'mdi mdi-database-search mdi-18px', path: '/monofasicos' },
          { text: 'Recebimentos XML', icon: 'mdi mdi-cloud-tags mdi-18px' ,path: '/recebimento-xml', visible: store_auth.getIsAdmin },
        ]
      }
    ]

    onMounted(() => {
      updateSelection();
      if (store.isDrawerOpen === false) {
        treeViewRef.value.instance.collapseAll()
      }
    })

    const isLargeScreen = mediaQuery.largeMedia()
    const items = navigation.map((item) => {
      if (item.path && !(/^\//.test(item.path))) {
        item.path = `/${item.path}`;
      }
      return { ...item, expanded: isLargeScreen }
    });

    function navigate(e: any) {
      router.push({ path: e.itemData.path });
      
      treeViewRef.value.instance.selectItem(e.itemData.path);
      treeViewRef.value.instance.collapseAll();
      store.isDrawerOpen = false;
      
      const pointerEvent = e.event;
      pointerEvent.stopPropagation();
    }

    function updateSelection() {
      if (!treeViewRef.value || !treeViewRef.value.instance) {
        return;
      }
      treeViewRef.value.instance.selectItem(router.currentRoute.value.path)
      treeViewRef.value.instance.expandItem(router.currentRoute.value.path)
    }

    watch(
      () => router.currentRoute.value.path,
      () => {
        updateSelection();
      }
    )

    watch(
      () => store.isDrawerOpen,
      () => {
        if (store.isDrawerOpen === false) {
          treeViewRef.value.instance.collapseAll();
        } else {
          updateSelection();
        }
      }
    )

    return {
      items,
      navigate,
      treeViewRef,
    }
  }
})