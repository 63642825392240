
import config from 'devextreme/core/config';
import ptMessages from 'devextreme/localization/messages/pt.json'
import {locale, loadMessages} from 'devextreme/localization'


loadMessages(ptMessages)
locale(navigator.language)

config({
  defaultCurrency: 'BRL',
  editorStylingMode: 'outlined',
})
