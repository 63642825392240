import eventBus from '../../helpers/event-bus';
import mediaQuery from '../../helpers/media-query';
import { DxPopup, DxToolbarItem, DxPosition } from 'devextreme-vue/ui/popup';
import { computed, defineAsyncComponent, defineComponent, onBeforeUnmount, onMounted, reactive, markRaw } from 'vue';

const defaultSize = { width: 600, height: 440 }

export default defineComponent({
  name: 'PopupFactory',
  setup() {
    const stateRaw = markRaw({
      title: '',
      size: { },
      component: { },
      props: { },
    })
    const state = reactive({
      isVisible: false,
      lessThanLg: computed(() => mediaQuery.lessThanLg()),
      stateRaw
    })
    const popup = eventBus.usePopup()

    function PopupToggle(payload: any) {
      if (payload.status) {
        state.stateRaw.component = payload.component;
        state.stateRaw.props = payload.props;
        state.stateRaw.title = payload.title;
        state.stateRaw.size = payload.size ?? defaultSize
      } else {
        state.stateRaw.component = { };
        state.stateRaw.props = '';
        state.stateRaw.title = '';
        state.stateRaw.size = defaultSize;
      }

      state.isVisible = payload.status
    }

    onMounted(() => {
      popup.listen(PopupToggle)
    })

    onBeforeUnmount(() => {
      popup.off(PopupToggle)
    })

    return {
      PopupToggle,
      state,
    }
  },
  components: {
    DxPopup,
    DxToolbarItem,
    DxPosition,
    FormCliente: defineAsyncComponent(() => import('../form-cliente/index.vue')),
    FormUsuario: defineAsyncComponent(() => import('../form-usuario/index.vue')),
    FormResetSenha: defineAsyncComponent(() => import('../form-usuario/form-reset-password/index.vue')),
    FormContabilista: defineAsyncComponent(() => import('../form-contabilista/index.vue'))
  }
})