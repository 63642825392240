<template>
  <div id="app-header">
    <DxToolbar id="dxToolbar">
      <DxItem
        widget="dxButton"
        location="before"
        :options="state.menuButtonOptions"
      />
      <DxItem location="before"><img :src="state.logo" alt="Logo" /></DxItem>
      <DxItem
        location="after"
        location-in-menu="always"
        :visible="!state.hiddenUserName"
      >
        <div class="user-name"><i class="mdi mdi-account-circle mdi-light mdi-18px mr-3"/>{{ auth.currentUser?.nome }}</div>
      </DxItem>
      <DxItem
        widget="dxButton"
        location="after"
        location-in-menu="always"
        :options="state.logoutButtonOptions"
      />
    </DxToolbar>
  </div>
</template>

<script src="./index.ts"></script>

<style lang="postcss" scoped>
#app-header {
  @apply bg-brand-accent text-white shadow-md z-10 flex items-center justify-between px-1 h-14 w-full fixed;
  grid-area: header;
}

#dxToolbar {
  @apply w-full bg-transparent;
}

img {
  @apply ml-2 w-36;
}

.user-name {
  @apply text-white text-lg mr-3 capitalize font-thin;
}

</style>