<template>
  <div id="app-footer">
    <p>
      <a :href="state.swhouse.url" target="_blank">{{ state.swhouse.name}}</a>
    </p>
    <p class="font-bold">v{{state.swhouse.currentVersion}}</p>
  </div>
</template>
<script src="./index.ts"></script>

<style lang="postcss" scoped>
#app-footer {
  @apply bg-brand-accent flex justify-between items-center px-5 w-screen
  text-white font-thin absolute h-6 text-xs bottom-0;
}
a {
  @apply text-white !important;
}
</style>