<template>
  <DxTreeView
    ref="treeViewRef"
    :items="items"
    key-expr="path"
    selection-mode="single"
    :focus-state-enabled="false"
    expand-event="click"
    @item-click="navigate"
    width="100%"
  >
  </DxTreeView>
</template>
<script src="./index.ts"></script>

<style lang="postcss">
.dx-treeview {
  @apply bg-brand-primary whitespace-nowrap;
}
.dx-treeview-item {
  @apply pl-2 text-white !important;
  border-bottom: 1px solid #c4c4c44d;
}

.dx-treeview .dx-icon {
  @apply mr-2 !important;
}

.dx-treeview-node {
  @apply px-0 !important;
}

.dx-treeview-toggle-item-visibility {
  @apply left-auto right-3 !important;
}
.dx-treeview-toggle-item-visibility-opened {
  @apply text-white !important;
}

.dx-rtl .dx-treeview-toggle-item-visibility {
  @apply left-3 right-auto !important;
}



li[aria-level="2"] i {
  @apply pl-2 border-none !important;
}

li[aria-level="2"] span {
  @apply pl-4 border-none !important;
}

.dx-treeview
  .dx-treeview-node-container
  .dx-treeview-node.dx-state-selected:not(.dx-state-focused)
  > .dx-treeview-item {
  background: transparent;
  color: transparent
}
.dx-treeview
  .dx-treeview-node-container
  .dx-treeview-node.dx-state-selected
  > .dx-treeview-item
  * {
  @apply text-green-400;
}
.dx-treeview
  .dx-treeview-node-container
  .dx-treeview-node:not(.dx-state-focused)
  > .dx-treeview-item.dx-state-hover {
  background-color: #cecece;
}
.dx-theme-generic
  .dx-treeview
  .dx-treeview-node-container
  .dx-treeview-node.dx-state-selected.dx-state-focused
  > .dx-treeview-item
  * {
  color: inherit;
}
</style>
